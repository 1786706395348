import "./Promo.css";

function Promo() {
  return (
    <section className="promo">
      <div className="promo__conteiner">
        <h1 className="promo__title">
          РЯБИНОВАЯ АЛЛЕЯ ГЕРОЕВ
        </h1>
      </div>
    </section>
  );
}

export default Promo;
